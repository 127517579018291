@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind base;
@tailwind components;
@tailwind utilities;
/* index.css or App.css */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(to right, #3b82f6, #9333ea);
  background-repeat: no-repeat;
  background-attachment: fixed;
}
